import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  return (
    <Carousel indicators={false} className="mw-50">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Karamapuram1.jpg"
          alt="Karamapuram"
        />
        <Carousel.Caption className='btn btn-primary'>
          <h3>Karamapuram</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Mettupalayam1.jpg"
          alt="Mettupalayam"
        />
        <Carousel.Caption className='btn btn-primary'>
          <h3>Mettupalayam</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Muttampatty1.jpg"
          alt="Muttampatty"
        />
        <Carousel.Caption className='btn btn-primary'>
          <h3>Muthampatty</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/images/Valapady1.jpg"
          alt="Valapady"
        />
        <Carousel.Caption className='btn btn-primary'>
          <h3>Valapady</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    
  );
}

export default UncontrolledExample;