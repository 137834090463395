import CompletedProjects from "../sections/CompletedProjects";
import WhyChooseUs from "../sections/WhyChooseUs";
import HomeHero from "../sections/HomeHero";
import Experience from '../sections/Experience';
import '../styles/Home.scss';
import About from "../sections/About";
import BookVisit from "../sections/BookVisit";

export default function Home() {
    return (
        <>
            <HomeHero />
            <CompletedProjects />
            <WhyChooseUs />
            <div className="align-items-center d-md-flex">
                <Experience className="px-md-5" />
                <About className="px-md-5" />
            </div>
            <BookVisit />
        </>
    )
}