import BookVisit from '../sections/BookVisit';
import OtherProjects from '../sections/OtherProjects';
import PageHero from '../sections/PageHero';
import PlayVideo from '../sections/PlayVideo';
export default function() {
    return (
        <>
            <PageHero slider="mettupalayam" />
            <PlayVideo page="mettupalayam" />
            <OtherProjects exclude="Mettupalayam"/>
            <BookVisit />
        </>
    );
}