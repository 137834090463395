import HomeSlider from '../components/HomeSlider';
import '../styles/CompletedProjects.scss';
export default function () {
    return (
        <section className="container my-5 d-md-flex flex-row-reverse align-items-center py-md-5">
            <div className='px-md-5'>
                <h3 className="poppins-semibold text-black section-title d-flex align-items-center">
                    COMPLETED PROJECTS
                    <span className="line-right"></span>
                </h3>
                <h1 className='section-subtitle poppins-bold mb-3'>
                    Quality, Reliability & Excellence
                </h1>
                <p className='mb-5 proxima-regular'>Here are our completed projects. With over 5 decades of experience, we are a company known as much for its beautiful, world-class plots as it is for following best practices in the industry.</p>
            </div>
            <HomeSlider />
        </section>
    );
}