import Carousel from 'react-bootstrap/Carousel';
import { sites } from '../constants';
export default function ({ exclude }) {
    const filterProjects = () => {
        let arr = [];
        sites.forEach(site => {
            if (!site.name.includes(exclude)) {
                arr.push((
                    <Carousel.Item key={site.name}>
                        <img
                            className="d-block w-100"
                            src={site.img}
                        />
                        <Carousel.Caption className='btn btn-primary mb-0'>
                            <h3>{site.name}</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            };
        })
        return arr;
    }
    return (
        <section className="container-fluid other-projects py-5">
            <h3 className="poppins-semibold text-black section-title d-flex align-items-center text-white container-md mt-md-5">
                <span className="line-right line-left"></span>
                OUR OTHER PROJECTS
                <span className="line-right"></span>
            </h3>
            <Carousel indicators={false} className='mt-5 container-md d-md-none'>
                {filterProjects()}
            </Carousel>
            <div className='d-none d-md-grid proj-wrapper container-md text-center my-5'>
                {sites.map(site => {
                    return (
                        <>
                            {site.name.includes(exclude) ? <></> : (
                                <div className='proj-container'>
                                    <img src={site.img} className='d-block w-100 overflow-hidden' />
                                    <h3 className='text-white text-uppercase pt-3 proxima-regular' style={{"letterSpacing"
                                    : "2px"}}>{site.name}</h3>
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
        </section>
    );
}