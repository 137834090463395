import { Button } from "react-bootstrap";

export default function HomeHero() {
    return (
        <section className="hero container-fluid text-center d-flex flex-column align-items-center justify-content-center align-items-md-start">
            <div className="container-fluid container-md text-center d-flex flex-column align-items-center justify-content-center align-items-md-start text-md-start">
                <div className="poppins-semibold text-white hero-title">
                    <h2>The first step to your</h2>
                    <h1>dream home</h1>
                </div>
                <Button className="poppins-extrabold" href="/contact-us">BOOK SITE VISIT</Button>
                <div className="hero-contact d-flex justify-content-between align-self-md-end">
                    <a href="tel:123123">
                        <i className="bi bi-telephone"></i>
                        <span className="poppins-medium">CALL</span>
                    </a>
                    <a href="mailto:hello@dmcgardens.com">
                        <i className="bi bi-envelope"></i>
                        <span className="poppins-medium">EMAIL</span>
                    </a>
                    <a href="https://wa.me/9842791427">
                        <i className="bi bi-whatsapp"></i>
                        <span className="poppins-medium">WHATSAPP</span>
                    </a>
                    <img className="phone-icon" src="/images/Call.png"/>
                </div>
            </div>
        </section>
    );
}