import Carousel from 'react-bootstrap/Carousel';
import { sliders } from '../constants';
export default function ({slider}) {
    let imgPath = window.innerWidth <=760 ? '/images/' : '/images/desktop/';
    return (
        <section className="site-hero">
            <Carousel>
                {sliders[slider].map(slide => (
                    <Carousel.Item style={{backgroundImage: `url(${imgPath}${slide})`}} key={slide}>
                    </Carousel.Item>
                ))}
            </Carousel>
        </section>
    );
}