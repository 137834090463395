import Ratio from 'react-bootstrap/Ratio';

export default function () {
    return (
        <section className="container-fluid section-video py-5 container-md">
            <Ratio aspectRatio={'16x9'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/k8A8x8B5MMY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Ratio>
        </section>
    );
}