import '../styles/CompletedProjects.scss';
import { Button } from "react-bootstrap";
export default function () {
    return (
        <section className="container my-5 px-md-5">
            <h3 className="poppins-semibold text-black section-title d-flex align-items-center">
                <span className="line-right line-left" hidden></span>
                ABOUT US
                <span className="line-right"></span>
            </h3>
            <h1 className='section-subtitle poppins-bold mb-3'>
                Building homes since 1970
            </h1>
            <p className='mb-5 proxima-regular'>Established in the year 1970, DMC Gardens has successfully carved a niche for itself in the ever-dynamic real estate industry over the last 52 years. A company known as much for its beautiful, world-class plots as it is for following best practices in the industry.</p>
            <div className='actions' hidden>
                <Button className="poppins-semibold">LEARN MORE</Button>
                <Button className="poppins-semibold btn-outline-dark">CONTACT</Button>
            </div>
        </section>
    );
}