export default function() {
    return (
        <main className="policy">
            <h1>Terms and Conditions</h1>
            <ol>
                <li>Only the Indian Residents/Non Resident Indians (NRIs)/Persons of Indian Origin (PIOs) who are competent to enter into contract under the Indian Contract Act, 1881 shall be eligible.</li>
                <li>The user shall be responsible to comply with all the statutory compliances as required from time to time under the applicable laws prevailing in India or the country of their residence or otherwise, including the laws applicable to NRIs/PIOs and the LLP shall not be liable for the same in any manner whatsoever.</li>
                <li>Website: Entire content of the website has been gathered to the best of our knowledge and belief, and the LLP endeavours to keep it up to date. In particular, we reserve all rights to update and/or modify and/or remove this data anytime and without any prior notice. Please note that the LLP holds no responsibility for incorrect descriptions and thereby excludes liability for any losses resulting from the contents of the website.</li>
                <li>In no event, the LLP shall be liable for any damages whatsoever, arising out of the use, inability to use, or the results of use of this site, any websites linked to this site, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages.</li>
                <li>The terms and conditions contained herein and in the Application Form shall be construed in accordance with the laws of India and any disputes/issues arising out of this transaction will be subject to the exclusive jurisdiction of Courts at Salem.</li>
                <li>By posting, uploading, inputting, providing or submitting any information or feedback (“Submission”) you are granting the LLP and /or its affiliated companies, and sublicensees (including third parties whose products, technologies and services use and interface with any specific parts of our digital products or service that includes the Submission) right to copy, reproduce, store, distribute, publish, export, adapt, edit and translate all or any part of Submission as may be reasonably required without any charge.</li>
                <li>While we endeavour to keep our data and information up to date, the LLP cannot assume responsibility for any errors or omissions in these materials made available on this website. LLP further does not guarantee the accuracy or completeness of the information, text, graphics, links or other items contained within these materials. LLP  may make changes to the materials, or to the products or services described herein, at any time without notice, and makes no commitment to update the information contained herein. The information, documentation, software, products, APIs or Web Services obtained by you from the website cannot be modified, copied, distributed, transmitted, displayed, performed, reproduced, published, licensed, create derivative works from, transferred, or sold.</li>
                <li>By agreeing to the Terms & Conditions mentioned here, you authorize the LLP to contact you via Whatsapp, Facebook Messenger, Instagram or any other social media or digital tool/platforms or the like, to keep you appraised of our products, event or any other promotions”</li>
            </ol>
        </main>
    );
}