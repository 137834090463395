import { Button } from "react-bootstrap";
export default function() {
    return (
        <section className="container-fluid section-book-visit text-center py-5">
            <div className="poppins-semibold text-white hero-title pt-md-5 mt-md-5">
                <h2>Book a site visit today and let us work together to</h2>
                <h1>build your dream house!</h1>
            </div>
            <Button className="poppins-extrabold mb-md-5" href="/contact-us">BOOK SITE VISIT</Button>
        </section>
    );
}