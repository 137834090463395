import BookVisit from '../sections/BookVisit';
import OtherProjects from '../sections/OtherProjects';
import PageHero from '../sections/PageHero';
import PlayVideo from '../sections/PlayVideo';
export default function() {
    return (
        <>
            <PageHero slider="muttampatty" />
            <PlayVideo page="muttampatty" />
            <OtherProjects exclude="Muttampatty"/>
            <BookVisit />
        </>
    );
}