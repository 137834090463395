import { Form, Button, InputGroup } from "react-bootstrap";

export default function () {
    return (
        <>
            <section className="contact container-fluid">
                <h3 className="poppins-semibold text-black section-title d-flex align-items-center mb-5 container">
                    <span className="line-right line-left"></span>
                    CONTACT US
                    <span className="line-right"></span>
                </h3>
                <Form className="poppins-semibold">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="name">NAME</InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="name"
                            required
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="email">E-MAIL</InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="email"
                            type="email"
                            required
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="phone">PHONE</InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="phone"
                            type="tel"
                            required
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="message">MESSAGE</InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="message"
                            as={'textarea'}
                            required
                        />
                    </InputGroup>
                    <Button variant="primary" type="submit">
                        SUBMIT
                    </Button>
                </Form>
            </section>
            <section className="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15869.507579212339!2d78.14473992640245!3d11.66557882848117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf0499493c25b%3A0x3e6aa0ff3567e74c!2sDMC%20Gardens%20-%20Palm%20Springs%20-%20Property%20Developers%20And%20Promoters!5e0!3m2!1sen!2sin!4v1676519642607!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" width="100%" height="300px"></iframe>
            </section>
        </>
    );
}