import React from "react";
import AnimatedNumbers from "react-animated-numbers";

export default function () {
    return (
        <section className="container-fluid section-experience py-5 text-center px-md-5">
            <div className="counters-wrapper py-md-5">
                <div className="counter my-3">
                    <span className="text-white poppins-semibold">
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={20}
                            fontStyle={{ fontSize: 40 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        ></AnimatedNumbers>
                        <sup>+</sup>
                    </span>
                    <h3 className="poppins-semibold fs-6">Years</h3>
                </div>
                <div className="counter my-3">
                    <span className="text-white poppins-semibold">
                        <AnimatedNumbers
                            includeComma
                            animateToNumber={50}
                            fontStyle={{ fontSize: 40 }}
                            configs={[
                                { mass: 1, tension: 220, friction: 100 },
                                { mass: 1, tension: 180, friction: 130 },
                                { mass: 1, tension: 280, friction: 90 },
                                { mass: 1, tension: 180, friction: 135 },
                                { mass: 1, tension: 260, friction: 100 },
                                { mass: 1, tension: 210, friction: 180 },
                            ]}
                        ></AnimatedNumbers>
                        <sup>+</sup>
                    </span>
                    <h3 className="poppins-semibold fs-6">Projects</h3>
                </div>
                <div className="counter my-3">
                    <span className="text-white poppins-semibold"><AnimatedNumbers
                        includeComma
                        animateToNumber={150}
                        fontStyle={{ fontSize: 40 }}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    ></AnimatedNumbers><sup>+</sup></span>
                    <h3 className="poppins-semibold fs-6">Projects</h3>
                </div>
                <div className="counter my-3">
                    <span className="text-white poppins-semibold"><AnimatedNumbers
                        includeComma
                        animateToNumber={400}
                        fontStyle={{ fontSize: 40 }}
                        configs={[
                            { mass: 1, tension: 220, friction: 100 },
                            { mass: 1, tension: 180, friction: 130 },
                            { mass: 1, tension: 280, friction: 90 },
                            { mass: 1, tension: 180, friction: 135 },
                            { mass: 1, tension: 260, friction: 100 },
                            { mass: 1, tension: 210, friction: 180 },
                        ]}
                    ></AnimatedNumbers><sup>+</sup></span>
                    <h3 className="poppins-semibold fs-6">Projects</h3>
                </div>
            </div>
        </section>
    );
}