export const karamapuramSlider = [
    'Karamapuram1.jpg',
    'Mettupalayam1.jpg',
    'Muttampatty1.jpg',
    'Valapady1.jpg'
];
export const sliders = {
    karamapuram: [
        'Karamapuram.jpg',
        ...karamapuramSlider
    ],
    mettupalayam: [
        'Mettupalayam.jpg',
        ...karamapuramSlider
    ],
    muttampatty: [
        'Muttampatty.jpg',
        ...karamapuramSlider
    ],
    valapady: [
        'Valapady.jpg',
        ...karamapuramSlider
    ]
};
export const sites = [
    {
        img: '/images/Karamapuram1.jpg',
        name: 'Karamapuram'
    },
    {
        img: '/images/Mettupalayam1.jpg',
        name: 'Mettupalayam'
    },
    { img: '/images/Muttampatty1.jpg', name: 'Muthampatty' },
    { img: '/images/Valapady1.jpg', name: 'Valapady' }
]
export const FEATURES = [
    {
        img: '/images/RERA_Approved.png',
        label: 'RERA Approved'
    },
    {
        img: '/images/DTCP_Approved.png',
        label: 'DTCP Approved'
    },
    {
        img: '/images/Bank_Financing.png',
        label: 'Bank Financing'
    },
    {
        img: '/images/Walking_Track.png',
        label: 'Walking Track'
    },
    {
        img: '/images/Gated_Community.png',
        label: 'Gated Community'
    },
    {
        img: '/images/Near_Schools_Colleges.png',
        label: 'Near Schools & Colleges'
    },
    {
        img: '/images/Gated_Community.png',
        label: 'Children\'s Park'
    },
    {
        img: '/images/Potable_Water.png',
        label: 'Portable Water'
    },
    {
        img: '/images/Solar Street Lights.png',
        label: 'Solar Street Lights'
    },
    {
        img: '/images/Outdoor Gym.png',
        label: 'Outdoor Gym'
    },
    {
        img: '/images/Roadside Avenue Trees.png',
        label: 'Roadside Avenue Trees'
    },
    {
        img: '/images/Concrete Drainage.png',
        label: 'Concrete Drainage'
    },
]