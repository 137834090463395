export default function() {
    return (
        <main className="policy">
            <h1>Privacy Policy</h1>

            <p>DMC Gardens and Promoters LLP, (as used herein, the name "DMC Gardens" includes DMC Gardens and Promoters LLP) makes every effort to treat your personal information as safely and securely as reasonably possible.</p>

            <p>Please read the following privacy policy to better understand how your personal information may be collected and used as you access various areas of our website.</p>

            <p>Your information may be collected by DMC Gardens, disclosed to third parties engaged for providing services to DMC Gardens, and used by such third parties and / or DMC Gardens. This Privacy Policy describes how we may use the information collected from you. Reasons for collecting information includes but not limited to the following:</p>
            <ul>
                <li>For registration to access information provided in the DMC Gardens web-site;</li>
                <li>For registration to subscribe to our newsletters;</li>
                <li>For participation in discussion forums on our site;</li>
                <li>For providing a more personalized and meaningful experience on the DMC Gardens web-site.</li>
            </ul>

            <p>We occasionally employ / engage third party agencies / individuals to perform functions on behalf of DMC Gardens. For example, such third party / individuals may fulfill your orders from our end, send you postal mail and e-mail, remove repetitive information from our customer lists, analyze our data, provide us with marketing assistance, or any other assistance as may be deemed fit.  These third party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. We do not sell, rent, share, or otherwise disclose personally identifiable information from customers for commercial purposes. We do not intend to disclose any personal information of yours without your consent to any third party who is not bound to act on our behalf unless such disclosure is required by law.</p>

            <p>This website and the data collected through it is the sole property of DMC Gardens and Promoters LLP. (the use of “DMC Gardens” hereinafter, implies DMC Gardens and Promoters LLP). The information contained in this site is for general information on matters of interest only. www.dmcgardens.com and its associated websites provide information based on sources believed to be accurate and reliable. Users are responsible for seeking the advice of professionals, as appropriate, regarding the information, opinions, advice or content available at DMC Gardens makes no representations and disclaims all warranties, whether express, implied, or statutory, including but not limited to warranties as to accuracy, reliability, usefulness, completeness, merchantability, or fitness of information for any particular purpose, non-infringement and any damages ensuing thereby. In no event shall DMC Gardens and Promoters LLP or its founders, partners, officers, agents, employees or content providers be liable for any direct, indirect, special, incidental, exemplary, punitive or consequential damages, whether or not advised of the possibility of such damages including without limitation, those pertaining to lost profits to any DMC Gardens and Promoters LLP subscriber, participant, customer, or other person or entity for furnishing of information or arising from the contents/use of this website. DMC Gardens cannot and will not guarantee that this website is free from viruses or anything else that has properties that might damage your system.</p>
        </main>
    );
}