import { Image } from "react-bootstrap";
import { FEATURES } from '../constants';

export default function () {
    return (
        <section className="container-fluid py-5 why-choose-us">
            <h3 className="poppins-semibold text-black section-title d-flex align-items-center container-md my-md-5">
                <span className="line-right line-left"></span>
                WHY CHOOSE US
                <span className="line-right"></span>
            </h3>
            <div className="feature-wrapper container-md pb-md-5">
                {FEATURES.map(feature => (
                    <div className="feature-container text-center my-md-3" key={feature.label}>
                        <Image fluid src={feature.img} />
                        <div className="text-center d-block feature-caption poppins-semibold text-black">{feature.label}</div>
                    </div>
                ))}
            </div>
        </section>
    );
}