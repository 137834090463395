import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';
import { useLocation } from 'react-router-dom'

function OffcanvasExample() {
  const location = useLocation();
  return (
    <>
      <Navbar bg="transparent" expand="sm" className="mb-3 mb-md-0" >
        <Container fluid="sm" className='justify-content-center py-md-3'>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} className="position-absolute">
            <span className='menubar-line'></span>
            <span className='menubar-line'></span>
            <span className='menubar-line'></span>
          </Navbar.Toggle>
          <Navbar.Brand href="/">
            <Image fluid src='/images/DMCLogo.png' />
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton />
            <Offcanvas.Body className='d-flex flex-column'>
              <Nav className="justify-content-center flex-grow-1 justify-content-md-end">
                <Nav.Link href="/" className={location.pathname === "/" ? "active" : ""}>Home</Nav.Link>
                <Nav.Link href="/about-us" className={location.pathname === "/about-us" ? "active" : ""}>About Us</Nav.Link>
                <NavDropdown
                  title="Ongoing Projects"
                  className='bg-transparent text-white'
                  id={`offcanvasNavbarDropdown-expand-sm`}
                >
                  <NavDropdown.Item className='nav-link text-white' href="/karamapuram">Karamapuram</NavDropdown.Item>
                  <NavDropdown.Item className='nav-link text-white' href="/mettupalayam">
                    Mettupalayam
                  </NavDropdown.Item>
                  <NavDropdown.Item className='nav-link text-white' href="/muthampatty">
                    Muthampatty
                  </NavDropdown.Item>
                  <NavDropdown.Item className='nav-link text-white' href="/valapady">
                    Valapady
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/completed-projects" className={location.pathname === "/completed-projects" ? "active" : ""}>Completed Projects</Nav.Link>
                <Nav.Link href="/contact-us" className={location.pathname === "/contact-us" ? "active" : ""}>Contact Us</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default OffcanvasExample;